@import url("https://fonts.googleapis.com/css?family=Asap:400,700");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/select/lib/css/blueprint-select.css";

* {
  outline: none !important;
}

body {
  margin: 0;
  width: 100vw;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
